import Api from '@/axios'

export default {
  getBlogs (page = 1) {
    return Api().get(`pages/blogs?page=${page}`)
  },
  getBlogDetails (id) {
    return Api().get(`pages/blogs/${id}`)
  }
}
