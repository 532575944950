<template>
  <b-container class="pt-3">
    <b-row class="mb-3">
      <b-col class="d-flex justify-content-between">
        <curva-title :title="$t('blogs.bDetails')"/>
      </b-col>
    </b-row>
    <b-row><b-col>
      <iq-card class="details-container blog bg-transparent">
        <b-row>
          <b-col lg="5" class="text-center mb-4">
            <img class="w-100 mb-3" :src="blogDetails.image"/>
          </b-col>
          <b-col lg="7">
            <b-row>
              <b-col lg="12">
                <label class="mb-4">{{ $t('blogCard.posted') }} {{ blogDetails.date }}</label>
                <h3 class="text-right text-warning mb-4">{{ blogDetails.name }}</h3>
                <p class="text-right">{{ blogDetails.post.replaceAll('|', '\n') }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </iq-card>
    </b-col></b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import BlogsServices from '@/modules/blogs/services/blogs.services'
export default {
  props: {
    blogDetails: {
      default () {
        return {
          title: 'Real Madrid announces the appointment of Ancelotti as coach for 3 seasons, replacing Zidane',
          date: '10 Jan, 2022 - 10:48 PM',
          image: require('@/assets/images/curva/blog-default.png'),
          post:
              'The Spanish club Real Madrid announced, this evening, Tuesday, the signing of Italian coach Carlo Ancelotti to coach the royal team for a period of 3 years, to succeed French Zinedine Zidane, who left after the end of the 2020-2021 football season.\n' +
              '\n' +
              '"Real Madrid announces that Carlo Ancelotti will be the first new player for the three seasons," Real Madrid said in its official statement.\n' +
              '\n' +
              'Carlo Ancelotti will then appear at a press conference to present it to the media remotely."\n' +
              'The Italian Carlo Ancelotti, led Real Madrid in June 2013 and lasted until May 2015, and crowned him with the European Champions League for the tenth time in the history of the royal.\n' +
              '\n' +
              'Ancelotti led Real Madrid in 119 matches in all competitions. The Meringue team managed to achieve 89 victories, 14 draws and 16 losses. Under his leadership, he scored 322 goals and conceded 103 goals.\n' +
              'This content has been moved from the seventh day.'
        }
      }
    }
  },
  created () {
    BlogsServices.getBlogDetails(this.$route.params.id).then(res => {
      this.blogDetails = res.data.data
    })
  },
  mounted () {
    core.index()
  }
}
</script>
